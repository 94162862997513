import { Media } from './FormControls';
import { KeyValue, UUIDName } from './GenericObject';
import { CATALOG_ORGANISATIONS_SORT_OPTIONS, CATALOG_PORTFOLIOS_SORT_OPTIONS, CATALOG_PROJECT_SORT_OPTIONS } from '../constants/Global/SortOptions';
import Utils from '../helpers/Utils';
import { Color } from '@angular-material-components/color-picker';
import { HomepageConfig, HomepageConfigDto } from 'src/app/_core/models/global-settings/HomepageConfig';
import { environment } from 'src/environments/environment';
import { DigitGroupingKeys, NumberFormatKeys } from '../constants/NumberFormats';
import { GIVE_BUTTON_BEHAVIOUR } from '../constants/GiveButtonBehavior';
import { InfluencedCountriesDelimiterKeys } from '../constants/InfluencedCountriesDelimiters';

export class GlobalConfig {
  applicationTitle: string = '';
  whiteLogo: Media = null;
  blackLogo: Media = null;
  authBackgroundImage: Media = null;
  championsVideoThumbnail: Media = null;
  championsVideo: Media = null;
  defaultCurrency: UUIDName = null;
  adminEmail: string = '';
  entityPriority: string = null;
  termsOfUse: string = '';
  privacyPolicy: string = '';
  helpVideosLink: string = '';
  authBottomText: string = '';
  displayRegisterLink: boolean = true;
  givingLinkAdminOnly: boolean = true;
  allowDonorsCreatePrivatePortfolios: boolean = true;
  displayLearnMoreButton: boolean = true;
  allowOrganizationApproval: boolean = false;
  allowProjectApproval: boolean = false;
  allowPortfolioApproval: boolean = false;
  catalogProjectsSortOrder: CATALOG_PROJECT_SORT_OPTIONS = CATALOG_PROJECT_SORT_OPTIONS.ALPHABETICAL;
  catalogOrganisationsSortOrder: CATALOG_ORGANISATIONS_SORT_OPTIONS = CATALOG_ORGANISATIONS_SORT_OPTIONS.ORGANISATION_NAME;
  catalogPortfoliosSortOrder: CATALOG_PORTFOLIOS_SORT_OPTIONS = CATALOG_PORTFOLIOS_SORT_OPTIONS.ALPHABETICAL;
  organisationProjectsLimit: number = null;
  dateFormat: string = '';
  authButtonBackgroundColor: Color = null;
  authButtonTextColor: Color = null;
  authTextColor: Color = null;
  footerColor: Color = null;
  companyName: string = '';
  footerParentWebsiteLink: string = '';
  homepageFocus: HomepageConfig = null;
  contactLink: string = '';
  newsLink: string = '';
  customLinkLabel: string = '';
  customLinkUrl: string = '';
  learnMoreLink: string = '';
  addressFormat: string = null;
  decimalSymbol: KeyValue = null;
  digitGroupingSymbol: KeyValue = null;
  digitGrouping: KeyValue = null;
  giveButton: GIVE_BUTTON_BEHAVIOUR = GIVE_BUTTON_BEHAVIOUR.STANDARD;
  showRedirectPopup: boolean = null;
  enableGoogleAnalytics: boolean = false;
  showPortfolioOrganizationCount: boolean = null;
  showPortfolioProjectCount: boolean = null;
  googleAnalyticsTrackingCode: string = '';
  displayCatalogSearchIcon: boolean = true;
  portfolioNotificationsToOwners: boolean = false;
  influencedCountriesDelimiters: KeyValue = null;
  testimonialEnabled: boolean = false;
  yearRange: number = null;
  displayChampionTabInEntityForms: boolean = null;
  displayGrantAwardedFieldsInChampionForm: boolean = null;

  constructor(response: GlobalConfigResponse = null) {
    if (response?.globalConfig.length) {
      response.globalConfig.forEach((item) => {
        if (!Object.keys(this).includes(item.key)) return;
        this[item.key] = typeof this[item.key] === 'string' ? item.value : JSON.parse(item.value);
      });
    }

    if (!this.applicationTitle) {
      this.applicationTitle = 'Application Title';
    }
    if (!this.whiteLogo) {
      this.whiteLogo = { url: 'Logo-on-black-background' } as Media;
    }
    if (!this.blackLogo) {
      this.blackLogo = { url: 'Logo-on-white-background' } as Media;
    }
    if (!this.authBackgroundImage) {
      this.authBackgroundImage = { url: 'Auth-backgrond-logo' } as Media;
    }
    if (!this.championsVideoThumbnail) {
      this.championsVideoThumbnail = { url: 'Champions-video-thumbnail' } as Media;
    }
    if (!this.championsVideo) {
      this.championsVideo = { url: 'Champions-video' } as Media;
    }
    if (!this.defaultCurrency) {
      this.defaultCurrency = new UUIDName('', 'USD');
    }
    if (!this.adminEmail) {
      this.adminEmail = 'admin@trustbridgeglobal.com';
    }
    if (!this.entityPriority) {
      this.entityPriority = [EntitiesPosition.ORGANISATIONS, EntitiesPosition.PROJECTS, EntitiesPosition.PORTFOLIOS] as unknown as string;
    }
    if (!this.termsOfUse) {
      this.termsOfUse = '';
    }
    if (!this.privacyPolicy) {
      this.privacyPolicy = '';
    }
    if (!this.helpVideosLink) {
      this.helpVideosLink = '';
    }
    if (!this.authBottomText) {
      this.authBottomText = 'GivingSpace';
    }
    if (typeof this.displayRegisterLink !== 'boolean') {
      this.displayRegisterLink = true;
    }
    if (typeof this.givingLinkAdminOnly !== 'boolean') {
      this.givingLinkAdminOnly = true;
    }
    if (typeof this.allowDonorsCreatePrivatePortfolios !== 'boolean') {
      this.allowDonorsCreatePrivatePortfolios = true;
    }
    if (typeof this.displayLearnMoreButton !== 'boolean') {
      this.displayLearnMoreButton = true;
    }
    if (typeof this.allowOrganizationApproval !== 'boolean') {
      this.allowOrganizationApproval = false;
    }
    if (typeof this.allowProjectApproval !== 'boolean') {
      this.allowOrganizationApproval = false;
    }
    if (typeof this.allowPortfolioApproval !== 'boolean') {
      this.allowOrganizationApproval = false;
    }
    if (!this.catalogProjectsSortOrder) {
      this.catalogProjectsSortOrder = CATALOG_PROJECT_SORT_OPTIONS.ALPHABETICAL;
    }
    if (!this.catalogOrganisationsSortOrder) {
      this.catalogOrganisationsSortOrder = CATALOG_ORGANISATIONS_SORT_OPTIONS.ORGANISATION_NAME;
    }
    if (!this.catalogPortfoliosSortOrder) {
      this.catalogPortfoliosSortOrder = CATALOG_PORTFOLIOS_SORT_OPTIONS.ALPHABETICAL;
    }
    if (!this.organisationProjectsLimit) {
      this.organisationProjectsLimit = 10;
    }
    if (!this.dateFormat) {
      this.dateFormat = 'M/d/yyyy';
    }
    if (!this.authButtonBackgroundColor) {
      this.authButtonBackgroundColor = Utils.hexToPickerColor('#a78b58');
    } else {
      this.authButtonBackgroundColor = Utils.hexToPickerColor(this.authButtonBackgroundColor.hex);
    }
    if (!this.authButtonTextColor) {
      this.authButtonTextColor = Utils.hexToPickerColor('#fff');
    } else {
      this.authButtonTextColor = Utils.hexToPickerColor(this.authButtonTextColor.hex);
    }
    if (!this.authTextColor) {
      this.authTextColor = Utils.hexToPickerColor('#fff');
    } else {
      this.authTextColor = Utils.hexToPickerColor(this.authTextColor.hex);
    }
    if (!this.footerColor) {
      this.footerColor = Utils.hexToPickerColor('#3e3d3d');
    } else {
      this.footerColor = Utils.hexToPickerColor(this.footerColor.hex);
    }
    if (!this.footerParentWebsiteLink) {
      this.footerParentWebsiteLink = '';
    }
    if (!this.companyName) {
      this.companyName = '';
    }
    this.homepageFocus = new HomepageConfig(response?.homepageFocus);
    if (!this.contactLink) {
      this.contactLink = '';
    }
    if (!this.newsLink) {
      this.newsLink = '';
    }
    if (!this.learnMoreLink) {
      this.learnMoreLink = `${environment.url}/app/learn-more`;
    }
    if (!this.customLinkLabel) {
      this.customLinkLabel = '';
    }
    if (!this.customLinkUrl) {
      this.customLinkUrl = '';
    }
    if (!this.addressFormat) {
      this.addressFormat = [
        AddressFormat.STREETNUMBER,
        AddressFormat.STREETNAME,
        AddressFormat.CITY,
        AddressFormat.STATEPROVINCE,
        AddressFormat.POSTALCODE,
        AddressFormat.COUNTRY,
        AddressFormat.LANDMARK_ADDRESS,
      ] as unknown as string;
    }
    if (!this.decimalSymbol) {
      this.decimalSymbol = new KeyValue(NumberFormatKeys.DOT, '.');
    }
    if (!this.digitGroupingSymbol) {
      this.digitGroupingSymbol = new KeyValue(NumberFormatKeys.COMMA, ',');
    }
    if (!this.digitGrouping) {
      this.digitGrouping = new KeyValue(DigitGroupingKeys.DIGIT_GROUPING_1, '123,456,789');
    }
    if (!this.giveButton) {
      this.giveButton = GIVE_BUTTON_BEHAVIOUR.STANDARD;
    }
    if (typeof this.showRedirectPopup !== 'boolean') {
      this.showRedirectPopup = false;
    }

    if (typeof this.enableGoogleAnalytics !== 'boolean') {
      this.enableGoogleAnalytics = false;
    }

    if (!this.googleAnalyticsTrackingCode) {
      this.googleAnalyticsTrackingCode = '';
    }

    if (typeof this.showPortfolioOrganizationCount !== 'boolean') {
      this.showPortfolioOrganizationCount = false;
    }

    if (typeof this.showPortfolioProjectCount !== 'boolean') {
      this.showPortfolioProjectCount = false;
    }

    if (typeof this.displayCatalogSearchIcon !== 'boolean') {
      this.displayCatalogSearchIcon = true;
    }

    if (typeof this.portfolioNotificationsToOwners !== 'boolean') {
      this.portfolioNotificationsToOwners = false;
    }
    if (!this.influencedCountriesDelimiters) {
      this.influencedCountriesDelimiters = new KeyValue(InfluencedCountriesDelimiterKeys.COMMA, ',');
    }
    if (typeof this.testimonialEnabled !== 'boolean') {
      this.testimonialEnabled = false;
    }
    if (!this.yearRange) {
      this.yearRange = 10;
    }
    if (typeof this.displayChampionTabInEntityForms !== 'boolean') {
      this.displayChampionTabInEntityForms = false;
    }
    if (typeof this.displayGrantAwardedFieldsInChampionForm !== 'boolean') {
      this.displayGrantAwardedFieldsInChampionForm = false;
    }
  }
}

export enum EntitiesPosition {
  PROJECTS = 'PROJECTS',
  ORGANISATIONS = 'ORGANISATIONS',
  PORTFOLIOS = 'PORTFOLIOS',
}

export interface GlobalConfigResponse {
  globalConfig: GlobalConfigItemDto[];
  homepageFocus: HomepageConfigDto;
}

export interface GlobalConfigItemDto {
  key: string;
  value: string;
}

export enum AddressFormat {
  STREETNUMBER = 'STREET_NUMBER',
  STREETNAME = 'STREET_NAME',
  CITY = 'CITY',
  STATEPROVINCE = 'STATE_PROVINCE',
  POSTALCODE = 'POSTAL_CODE',
  COUNTRY = 'COUNTRY',
  LANDMARK_ADDRESS = 'LANDMARK_ADDRESS',
}
